import CssBaseline from "@material-ui/core/CssBaseline";
import { useContext } from "react";
import NoPermission from "../components/assets/NoPermission";
import DashboardPage from "../components/pages/dashboard/index";
import UserContext from "../src/UserContext";

function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    (JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin ||
      JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser)
  ) {
    return "authenticated";
  } else {
    return "notauthenticated";
  }
}

export default function Home() {
  let { keycloakstate } = useContext(UserContext);

  return (
    <>
      {getUserType(keycloakstate) == "authenticated" ? (
        <div>
          <CssBaseline />
          <DashboardPage />
        </div>
      ) : (
        <div>
          <NoPermission />
        </div>
      )}
    </>
  );
}
